<template @changeToggle="toggle = $event">
  <PageTemplate @changeToggle="toggle = $event">
    <template slot="title">{{ this.$t("products") }}</template>
    <template slot="content">
      <v-row align="start" justify="start" class="ma-0">
        <product-group-view v-for="product in subProductGroups" :key="product.codePath"
          style="margin: 8px; max-width: 298px;" @click.native="subProductGroupsAction(product.codePath)">
          <template slot="productGroupsName">{{ product.name }}</template>
        </product-group-view>
      </v-row>
    </template>
    <template slot="content">
      <v-layout>
        <v-layout align-start justify-start row wrap style="margin: 0;" v-if="!this.toggle">
          <ProductListItem v-for="product in subProducts" :key="product.codePath" style="margin: 0px;"
            :product="product" />
        </v-layout>
        <v-layout align-start justify-start row wrap style="margin: 0;" v-if="this.toggle">
          <product-view v-for="product in subProducts" :key="product.codePath" style="margin: 0px;" :product="product" />

        </v-layout>
        <not-found v-show="noData" />
      </v-layout>
    </template>
    <template slot="sortMenu">
      <v-menu bottom left>
        <template v-slot:activator="{ on }">
          <v-btn dark icon v-on="on">
            <img src="../../../assets/Icon_up-down.svg" style="width: 1.6rem;">
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item @click="byNameASC()">
            <v-list-item-title>{{ this.$t("by_name_asc") }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="byNameDESC()">
            <v-list-item-title>{{ this.$t("by_name_desc") }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="byCodeASC()">
            <v-list-item-title>{{ this.$t("by_code_asc ") }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="byCodeDESC()">
            <v-list-item-title>{{ this.$t("by_code_desc") }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="byGroup()">
            <v-list-item-title>{{ this.$t("by_group") }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </PageTemplate>
</template>

<script>

import PageTemplate from "../../templates/PageTemplate";
import ProductView from "../../partial/ProductView";
import { mapActions } from "vuex";
import Constants from "@/Constants";
import NotFound from "@/components/pages/scada/NotFound";
import ProductGroupView from "../../partial/ProductGroupView";
import ProductListItem from "@/components/partial/ProductListItem";

export default {
  name: "SubProduct",
  components: { PageTemplate, ProductView, NotFound, ProductGroupView, ProductListItem },
  data() {
    return {
      subProducts: {},
      subProductGroups: [],
      noData: false,
      toggle: true,

    };
  },
  created() {
    this.codePath = this.$route.params.codePath;
    console.log(this.codePath);
    this.getSubProducts();
    this.getSubProductGroups();
  },
  methods: {
    subProductGroupsAction(item) {
      console.log(item);
      this.showProgressBar();
      this.codePath = item
      this.getSubProducts();
      this.getSubProductGroups();
    },
    getSubProductGroups() {
      this.$http
        .get(Constants.apiMethod.productGroups.concat(this.codePath))
        .then((response) => {
          this.subProductGroups = response.data.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
          console.log(this.subProductGroups);
        });
    },
    getSubProducts() {
      this.$http
        .get(
          Constants.apiMethod.products
            .concat(this.codePath)
            .concat("&includeRelatedObjects=1")
        )
        .then((response) => {
          if (response.data.length === 0) {
            // this.noData = !this.noData;
            this.$store.dispatch("hideProgressBar");
          } else {
            this.subProducts = response.data;
            console.log(this.subProducts);
            this.$store.dispatch("hideProgressBar");
          }
        });
    },
    subProductAction(product) {
      this.$router.push({
        name: "ProductDetailPage",
        params: { product: product },
      });
    },
    byNameASC() {
      this.subProducts.sort((a, b) => (a.name > b.name ? 1 : -1));
    },
    byNameDESC() {
      this.subProducts.sort((a, b) => (a.name < b.name ? 1 : -1));
    },
    byCodeASC() {
      this.subProducts.sort((a, b) => (a.code > b.code ? 1 : -1));
    },
    byCodeDESC() {
      this.subProducts.sort((a, b) => (a.code < b.code ? 1 : -1));
    },
    byGroup() {
      this.subProducts.sort((a, b) => (a.code < b.code ? 1 : -1));
    },
    ...mapActions(["showProgressBar", "hideProgressBar"]),
  },
};
</script>

<style scoped></style>
