<template>
  <page-template>
    <template slot="title">{{ this.$t("product") }}</template>
    <template slot="content" v-if="product">
      <v-row class="ma-8 pa-4  " justify="center">

        <v-col class="col-xl-5 col-lg-6 boxContainer">
          <div class="box" style="">
            <div class="ribbon ribbon-top-left" v-show="product.product.campaignCode">
              <span :style="ribbon">{{ product.product.campaignCode }}</span>
            </div>
            <v-card class="elevation-5 ma-0" style="height: 578px;min-width: 440px;margin-bottom: 16px;">
              <v-col cols="12">
                <v-btn x-large icon @click="favate()" :color="color" right absolute>
                  <v-icon>mdi-star</v-icon>
                </v-btn>
              </v-col>
              <v-layout align-center justify-center fill-height style="">
                <button v-if="this.imagesLength > 1" class="prev " @click="prevSlider()">&#10094;</button>
                <button v-if="this.imagesLength > 1" class="next " @click="nextSlider()">&#10095;</button>
                <img class="sliderImg" v-show="imageShow" :src="this.getImageUrl(this.indexSlide)" alt
                  :style="imageStyle" />
                <img v-show="!imageShow" :src="require('../../../assets/box_opened_vector_.png')" alt
                  style="height: 100%;" />
              </v-layout>
            </v-card>
          </div>
        </v-col>

        <v-col class="col-xl-6 col-lg-5">
          <v-card class="elevation-4" style="margin-top: 0px; margin-bottom: 16px; min-width: 480px;">
            <v-row class="ma-0 pa-2" justify="center" align="center">
              <div style="font-size: 21px">{{ product.product.name }}</div>
            </v-row>

            <v-row style="margin-top: -21px">
              <v-col>
                <v-row class="ma-0" justify="center">
                  <div style="color: #e3000f;font-size: 21px;">
                    {{ product.product.uDisplayPrice.toFixed(2) }}
                    {{ product.product.priceCurrencyCode }}
                  </div>                 
                </v-row>
              </v-col>
              <v-col v-show="product.product.packageUnit !== product.product.unit">
                <v-row class="ma-0" justify="center">
                  <div style="color: #e3000f;font-size: 21px;">
                    {{ product.product.packDisplayPrice }}
                    {{ product.product.priceCurrencyCode }}
                  </div>
                </v-row>
              </v-col>
            </v-row>

            <v-row style="margin-top: -21px">
              <v-col>
                <v-row class="ma-0" justify="center">
                  <div>{{ product.product.unit }} &nbsp;(</div>
                  <div v-show="$store.state.userProfile.currentWholesaler.settings.displayPricesWithTax">{{ this.$t("with_tax") }})</div> 
                  <div v-show="!$store.state.userProfile.currentWholesaler.settings.displayPricesWithTax">{{ this.$t("without_tax") }})</div> 
                </v-row>
              </v-col>
              <v-col v-show="product.product.packageUnit !== product.product.unit">
                <v-row class="ma-0" justify="center">
                  <div>{{ product.product.packageUnit }} &nbsp;(</div>
                  <div v-show="$store.state.userProfile.currentWholesaler.settings.displayPricesWithTax">{{ this.$t("with_tax") }})</div> 
                  <div v-show="!$store.state.userProfile.currentWholesaler.settings.displayPricesWithTax">{{ this.$t("without_tax") }})</div> 
                </v-row>
              </v-col>
            </v-row>
          </v-card>

          <v-card class="elevation-4" style="margin-bottom: 16px; min-width: 480px;">
            <v-row class="ma-0">
              <v-col cols="3">{{ this.$t("code") }} :</v-col>
              <v-col>{{ product.product.code }}</v-col>
            </v-row>

            <v-row class="ma-0">
              <v-col cols="3">{{ this.$t("packaging_quantity") }} :</v-col>
              <v-col>{{ product.product.packagingQuantity }}</v-col>
            </v-row>

            <v-row class="ma-0">
              <v-col cols="3">{{ this.$t("product_group") }} :</v-col>
              <v-col>{{ product.product.productGroup.namePath }}</v-col>
            </v-row>
          </v-card>

          <v-card class="elevation-5" style="min-width: 480px;">
            <v-row class="ma-0" align="center">
              <v-col cols="3">{{ this.$t("quantity") }}</v-col>
              <v-col cols="2">
                <v-text-field v-model="quantity" type="number" min="0" :max="stock" />
              </v-col>
              <v-col cols="6">
                <v-radio-group v-model="row" row :value="row" :mandatory="false">
                  <v-radio color="#F57C00" label="Ad" value="Ad" />
                  <v-radio v-show="product.product.packageUnit !== product.product.unit" color="#F57C00" label="Kol"
                    value="PK" />
                </v-radio-group>
              </v-col>
            </v-row>

            <v-row class="ma-0" v-show="rowAction">
              <v-col cols="5">
                {{ this.$t("package_content") }}
              </v-col>
              <v-col cols="5">
                {{ product.product.packagingQuantity }}
              </v-col>
            </v-row>

            <v-row class="ma-0">
              <v-col cols="5">
                {{ this.$t("unit_price") }}
              </v-col>
              <v-col cols="5">
                {{ basketItem.currentMainUnitPriceDisplay.toFixed(2) }}
                {{ $store.state.userProfile.currentWholesaler.settings.currencyCode }}
              </v-col>
            </v-row>

            <v-row class="ma-0" v-show="rowAction">
              <v-col cols="5">
                {{ this.$t("package_price") }}
              </v-col>
              <v-col cols="5">
                {{ basketItem.currentPackageUnitPriceDisplay.toFixed(2) }}
                {{ $store.state.userProfile.currentWholesaler.settings.currencyCode }}
              </v-col>
            </v-row>

            <v-row class="ma-0">
              <v-col cols="5">
                {{ this.$t("total_price") }}
              </v-col>
              <v-col cols="5">
                {{ basketItem.totalPriceDisplay.toFixed(2) }}
                {{ $store.state.userProfile.currentWholesaler.settings.currencyCode }}
              </v-col>
            </v-row>


            <v-card-actions>
              <!--              <v-flex xs12>-->
              <v-layout justify-start>
                <v-btn color="red" class="body-2" style="color: white;" @click="removeProductInBasket()">{{
                  this.$t("remove") }}
                </v-btn>
              </v-layout>
              <v-layout justify-end>
                <v-btn color="accent" text class="body-2" @click="cancelAdd()">{{ this.$t("cancel") }}
                </v-btn>
                <v-btn color="accent" class="body-2" style="color: white;" @click="addBasket()">{{ this.$t("ok") }}
                </v-btn>
              </v-layout>
            </v-card-actions>
          </v-card>
        </v-col>

      </v-row>
      <v-snackbar v-model="snackbar" center top color="accent" :multi-line="mode === 'multi-line'" :timeout="timeout"
        :vertical="mode === 'vertical'">
        <v-row align="center" justify="center">{{ text }}</v-row>
      </v-snackbar>
    </template>
  </page-template>
</template>

<script>

import PageTemplate from "../../templates/PageTemplate";
import Constants from "@/Constants";
import { mapActions } from "vuex";

export default {
  name: "ProductDetail",
  components: { PageTemplate },
  created() {
    this.showProgressBar();
    this.id = this.$route.params.id;
    this.fav.productId = this.id;
    this.getProduct();
    this.getFavoriteProducts();
  },
  data() {
    return {
      imagesLength: 0,
      indexSlide: 0,
      text: null,
      stock: 0,
      snackbar: false,
      mode: "",
      timeout: 3000,
      id: null,
      fav: { productId: "" },
      row: "KOL",
      product: {
        product: {
          uDisplayPrice: null,
          uPriceWithTax: null,
          packDisplayPrice: null,
          packPriceWithTax: null,
          priceCurrencyCode: null
        }
      },
      color: "grey",
      disabled: true,
      kolRestriction: true,
      quantity: "",
      imageShow: true,
      basketItem: {},
      imageStyle: "",
      index: "",
      proWebBasket: {},
      rowAction: true,
      checkProductInBasket: {},
      kolStyle: "margin-right: 55px",
      favoriteArrayProducts: [],
      ribbon: "background: #",
      radioButtonVisibile: true
    };
  },

  watch: {
    quantity() {
      this.showProgressBar();
      return this.generateBasketItemAction();

    },
    row() {
      this.rowAction = !this.rowAction;
      this.showProgressBar();
      return this.generateBasketItemAction();
    },
  },
  methods: {
    nextSlider() {
      if (this.indexSlide === this.imagesLength - 1) {
        return
      }
      this.indexSlide++;

    },
    prevSlider() {
      if (this.indexSlide === 0) {
        return
      }
      this.indexSlide--;

    },

    getFavoriteProducts() {
      this.$http.get(Constants.apiMethod.favoriteProducts).then((response) => {
        this.favoriteArrayProducts = response.data;
        console.log(this.id);
        this.favoriteArrayProducts.forEach((fav) => {
          if (fav.id === this.id) {
            console.log("test");
            this.color = "yellow";
          }
        });
      });
    },
    favate() {
      console.log(this.id);
      if (this.color === "yellow") {
        this.$http
          .delete(Constants.apiMethod.favoriteProduct, { params: this.fav })
          .then((response) => {
            console.log(response.data);
            this.color = "grey";
          });
      } else if (this.color === "grey") {

        this.$http
          .post(Constants.apiMethod.favoriteProduct, this.fav)
          .then((response) => {
            console.log(response.data);
            this.color = "yellow";
          });
      }
    },
    removeProductInBasket() {
      this.check();
      this.index = this.proWebBasket.findIndex(
        (s) => s.product.code === this.checkProductInBasket.product.code
      );
      console.log(this.index);
      this.proWebBasket.splice(this.index, 1);
      console.log(this.proWebBasket);
      if (this.proWebBasket.length === 0) {
        this.quantity = "";
        this.$store.state.badgeCounter = 0;
      } else {
        this.quantity = "";
        this.$store.state.badgeCounter--;
      }
      this.basketItem.currentMainUnitPriceDisplay = 0;
      this.basketItem.currentPackageUnitPriceDisplay = 0;
      this.basketItem.totalPriceDisplay = 0;
      this.basketItem.totalPriceValue = 0;
      localStorage.setItem("proWebBasket", JSON.stringify(this.proWebBasket));
      this.text = this.$t("remove_basket_item_success_message");
      this.snackbar = true;
    },
    cancelAdd() {
      this.quantity = this.checkProductInBasket.quantity;
      // this.quantity = 0
      this.$store.dispatch("routerGoBack")
    },
    addBasket() {
      if (this.quantity !== 0 || this.quantity !== "") {
        this.proWebBasket = JSON.parse(localStorage.getItem("proWebBasket"));
        console.log(
          this.proWebBasket.find(
            (s) => s.product.code === this.basketItem.product.code
          )
        );
        if (
          this.proWebBasket.find(
            (s) => s.product.code === this.basketItem.product.code
          )
        ) {
          this.index = this.proWebBasket.findIndex(
            (s) => s.product.code === this.basketItem.product.code
          );
          this.proWebBasket[this.index] = this.basketItem;
          localStorage.setItem(
            "proWebBasket",
            JSON.stringify(this.proWebBasket)
          );
        } else {
          this.proWebBasket.push(this.basketItem);
          localStorage.setItem(
            "proWebBasket",
            JSON.stringify(this.proWebBasket)
          );
        }
        this.$store.state.badgeCounter = this.proWebBasket.length;
        // this.snackbar = true
        this.text = this.$t("add_basket_item_success_message");
        this.snackbar = true;
      }
      this.$store.dispatch("routerGoBack")
    },
    generateBasketItemAction() {

      this.$http
        .get(
          Constants.apiMethod.generateBasketItem
            .concat("productId=")
            .concat(this.product.product.id)
            .concat("&quantity=")
            .concat(this.quantity)
            .concat("&unit=")
            .concat(this.row)
        )
        .then((response) => {
          this.basketItem = response.data;
          // this.checkProductInBasket = response.data;
          console.log(this.basketItem);
          this.hideProgressBar();
          // return this.basketItem
        });

    },
    getImageUrl(indexSlider) {
      var images = [];
      if (this.product.product.productImages.length > 0) {
        if (this.product.product.productImages[0].largeThumbnailUrl) {

          for (let i = 0; i < this.product.product.productImages.length; i++) {
            images.push(
              this.$store.state.userProfile.currentWholesaler.fileUrlRoot.concat(
                this.product.product.productImages[i].largeThumbnailUrl
              )
            );

          }
          this.imagesLength = images.length;
          console.log(this.imagesLength);

        } else if (this.product.product.productImages[0].mediumThumbnailUrl) {

          for (let i = 0; i < this.product.product.productImages.length; i++) {
            images.push(
              this.$store.state.userProfile.currentWholesaler.fileUrlRoot.concat(
                this.product.product.productImages[i].mediumThumbnailUrl
              )
            );

          }
          this.imagesLength = images.length;


        } else if (this.product.product.productImages[0].smallThumbnailUrl) {

          for (let i = 0; i < this.product.product.productImages.length; i++) {
            images.push(
              this.$store.state.userProfile.currentWholesaler.fileUrlRoot.concat(
                this.product.product.productImages[i].smallThumbnailUrl
              )
            );

          }
          this.imagesLength = images.length;

          this.imageStyle = "width: 40%;height: 40%;object-fit: contain;";
        } else {
          for (let i = 0; i < this.product.product.productImages.length; i++) {
            images.push(
              this.$store.state.userProfile.currentWholesaler.fileUrlRoot.concat(
                this.product.product.productImages[i].url
              )
            );

          }
          this.imagesLength = images.length;
        }
      } else {
        this.imageShow = false;
      }
      return images[indexSlider];
    },
    getProduct() {
      this.$http
        .get(
          Constants.apiMethod.productDetail
            .concat(this.id)
            .concat(
              "&includeRelatedObjects=true&basketItemQuantity=1&basketItemUnit=St"
            )
        )
        .then((response) => {
          this.product = response.data;
          this.stock = this.product.product.stockQuantity
          this.$store.state.campaignColors.forEach((item) => {
            if (item.code === this.product.product.campaignCode) {
              this.ribbon = this.ribbon + item.color;
            }
          })
          console.log(this.product);
          if (this.product.product.packageUnitPriceValue === null) {
            this.kolRestriction = false;
            this.kolStyle = "margin: 0px";
          }
          if (this.product.product.campaignCode === "") {
            this.product.product.campaignCode = null;
            console.log(this.product.product.campaignCode);
          }
          this.check();
          this.row = this.product.product.packageUnit;
          this.hideProgressBar();
        });
    },
    check() {
      this.proWebBasket = JSON.parse(localStorage.getItem("proWebBasket"));
      console.log("proWebBasket");
      if (
        this.proWebBasket.find(
          (s) => s.product.code === this.product.product.code
        )
      ) {
        this.checkProductInBasket = this.proWebBasket.find(
          (s) => s.product.code === this.product.product.code
        );
        this.quantity = this.checkProductInBasket.quantity;
      } else {
        this.quantity = 1;
      }
    },
    ...mapActions(["showProgressBar", "hideProgressBar"]),
  },
};
</script>

<style scoped>
.box {
  position: relative;
  /*max-width: 600px;*/
  /*width: 90%;*/
  /*height: 400px;*/
  /*background: #fff;*/
  /*box-shadow: 0 0 15px rgba(0,0,0,.1);*/
}

.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  font-weight: bold;
  font-size: 2rem;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;

}

@media screen and (max-width: 400px) {

  .prev,
  .next {
    display: none;
  }



}



.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.sliderImg {
  width: 90%;
  height: 85%;
  object-fit: contain;
}

.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

.prev {
  left: 0;
  border-radius: 3px 0 0 3px;
}

/* common */
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
  z-index: 1;
}

.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 5px solid #36733c;
}

.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background-color: #388e3c;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 700 18px/1 "Lato", sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
}

/* top left*/
.ribbon-top-left {
  top: -10px;
  left: -10px;
}

.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}

.ribbon-top-left::before {
  top: 0;
  right: 0;
}

.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}

.ribbon-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
}
</style>
